@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --brand-color: theme("colors.raw-brand-color");
}

html {
  scroll-behavior: smooth;
}
